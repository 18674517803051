import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const fbLink="https://www.facebook.com/dbpstudio/?eid=ARCEzz0g6d7Ok2oaeqHNzTjSK8vjYfXG1DwB5XQN6duFVnLjDM3gRDx2QrEA3Jq5gJYkLzZhx6sCoDLx";
const instaLink="https://www.instagram.com/studio.dbp/";
const linkedinLink="https://in.linkedin.com/company/studiodbp?trk=public_profile_topcard_current_company";


const Footer = (props) => {

  return (
    <Fragment>
      <div className="insidePageContainer__socialmedia">
        <div className="insidePageContainer__socialmedia-icon"><a href={fbLink} target="_blank"><FontAwesomeIcon icon={faFacebookSquare} size="2x" color="grey"/></a></div>
        <div className="insidePageContainer__socialmedia-icon"><a href={instaLink} target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" color="grey" /></a></div>
        <div className="insidePageContainer__socialmedia-icon"><a href={linkedinLink} target="_blank"><FontAwesomeIcon icon={faLinkedin} size="2x" color="grey" /></a></div>
        
        <div className="insidePageContainer__projectNavigation">
          {props.property && <a><Link to ={props.linkToPreviousProperty}> &lt; PREVIOUS PROJECT</Link></a>}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {props.property && <a><Link to ={props.linkToNextProperty}>NEXT PROJECT &gt;</Link></a>}     
        </div>
      </div>                 
    
      <div className="insidePageContainer__copyright">
        <p>© COPYRIGHT NINA'S HOME STAY 2020 | <a href="https://www.brahmaforces.com">WEBSITE DEVELOPED BY BRAHMAFORCES</a> </p>
      </div>
    </Fragment>
    )
}

export default Footer;
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/1.jpg"

class Contact extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/contact' className="insidePageContainer__link">Contact</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                <div className="copy">
                <p>Nina Kochhar</p>
                <p>43-A Oberoi Apartments</p>
                <p>2 Sham Nath Marg</p>
                <p>Civil Lines</p>
                <p>Delhi 110054</p>
                <p>India</p>
                <p>Email: <a href="mailto:nina.kochhar@gmail.com">nina.kochhar@gmail.com</a></p>
                <p>Mobile: +91 9811022326</p>
                <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14002.591674205858!2d77.2243489!3d28.670259!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd63858f9aa8f2bd4!2sNina%20Kochhar&#39;s%20Homestays!5e0!3m2!1sen!2sin!4v1584184869830!5m2!1sen!2sin" style={{width: "80%" ,height: "auto", frameborder:"0", style: "border:0"}} ></iframe>
                </div>
                </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default Contact;

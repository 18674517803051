import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/art.jpg"

class Art extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/art' className="insidePageContainer__link">Art</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                    <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                      <p>Nina's Home Stay is also home to an art collection and serigraphs made by the artist Arjuna are available for sale here. To peruse images online please see <a target='_blank' href="https://www.brahmaforces.com/art">artworks</a></p>
                    </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default Art;

import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Helmet from "react-helmet";


import Header from "./components/header";
import Slideshow from './components/slideshow.js';
import Contact from './components/contact.js';
import About from './components/about.js';
import Cooking from './components/cooking.js';
import Room from './components/room.js';
import Tariff from './components/tariff.js';
import Location from './components/location.js';
import Art from './components/art.js';
import Yoga from './components/yoga.js';
import Ratings from './components/ratings.js';
import Reviews from './components/reviews.js';

import TwitterImage from "./components/images/slideshow/1.jpg";
import FacebookImage from "./components/images/slideshow/2.jpg";

/* Variable Initialization*/
const title = "Nina's Home Stay | Delhi Bed Breakfast";
const description = "Nina's Home Stay is a very comfortable, safe, central and affordable Bed and Breakfast in New Delhi. ";
const keywords = "Delhi Bed Breakfast, Bed and Breakfast in Delhi, Room in Delhi, Nina's Home Stay, Hotel in Delhi";
const url = "https://delhibedbreakfast.com";
const sitename = "Nina's Home Stay | Delhi Bed Breakfast";
const fbappid = "";
const canonicalurl = "https://delhibedbreakfast.com";
const twittersite = "";
const twittercreator = "";

  class App extends Component {
    render() {
      return (
        <div>
           <Helmet>
          <title>
                { title }
          </title>
          <meta http-equiv="Content-Type" content="text/html" />
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="theme-color" content="#000000" />
          <meta
            name="description"
            content= { description } 
          />
          <meta
            name="keywords"
            content={ keywords }
          />

          <link rel="canonical" href={canonicalurl} />

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content= { title }
          />
          <meta
            property="og:description"
            content={ description }
          />
          <meta property="og:image" content={FacebookImage} />
          <meta property="og:url" content= {url} />
          <meta property="og:site_name" content={sitename} />
          <meta property="fb:app_id" content={fbappid} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={title}
          />
          <meta
            name="twitter:description"
            content={description}
          />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:image" content={TwitterImage} />
          <meta name="twitter:site" content={twittersite} />
          <meta name="twitter:creator" content={twittercreator} />
        </Helmet>


            <div className="pageContainer">
                <div className="pageContainer__header"><Header /></div>   
                  <Route path="/" exact component={Slideshow} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/about" component={About} />
                  <Route path="/cooking" component={Cooking} />
                  <Route path="/room" component={Room} />
                  <Route path="/tariff" component={Tariff} />
                  <Route path="/location" component={Location} />
                  <Route path="/art" component={Art} />
                  <Route path="/yoga" component={Yoga} />
                  <Route path="/ratings" component={Ratings} />
                  <Route path="/reviews" component={Reviews} />
            </div>

      </div>


      );
    }
  };
export default App;

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/2.jpg"

class Room extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/room' className="insidePageContainer__link">Room</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                        <p>ROOM with ENSUITE BATHROOM</p>
                        <ul>
                        <li>
                        <p>King-sized bed with spring mattress.</p>
                        </li>
                        <li>
                        <p>Extra single bed on demand at additional cost.</p>
                        </li>
                        <li>
                        <p>TV</p>
                        </li>
                        <li>
                        <p>Cupboard - with shelves and hanging space.</p>
                        </li>
                        <li>
                        <p>Dressing table with light.</p>
                        </li>
                        <li>
                        <p>Bedside lights.</p>
                        </li>
                        <li>
                        <p>Safe.</p>
                        </li>
                        <li>
                        <p>Rocking chair.</p>
                        </li>
                        <li>
                        <p>Hair dryer.</p>
                        </li>
                        <li>
                        <p>Mosquito prevention device.</p>
                        </li>
                        <li>
                        <p>Fresh linen and towels.</p>
                        </li>
                        <li>
                        <p>Bedroom slippers.</p>
                        </li>
                        <li>
                        <p>Bucket/sink/shower</p>
                        </li>
                        <li>
                        <p>Soap/detergent</p>
                        </li>
                        <li>
                        <p>Hanging device for drying underwear.</p>
                        </li>
                        </ul>
                      </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default Room;






import React from 'react';
import { render } from 'react-snapshot';

import './css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './serviceWorker';

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  , document.getElementById('root'));

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";
import RatingsCard from "./ratingsCard";

import Logo1 from "./images/logos/1.png";
import Logo2 from "./images/logos/2.png";
import Logo3 from "./images/logos/3.png";
import Logo4 from "./images/logos/4.png";
import Logo5 from "./images/logos/5.png";
import Logo6 from "./images/logos/6.png";
import Logo7 from "./images/logos/7.png";
import Logo8 from "./images/logos/8.png";
import Logo9 from "./images/logos/9.png";
import Logo10 from "./images/logos/10.png";

const tripAdvisor =`https://www.tripadvisor.in/Hotel_Review-g304551-d1844765-Reviews-Nina_Kochhar_s_Homestay-New_Delhi_National_Capital_Territory_of_Delhi.html`;
const booking=`https://www.booking.com/hotel/in/nina-kochhar-39-s-homestay-new-delhi2.en-gb.html?aid=356980;label=gog235jc-1DCAsobEIlbmluYS1rb2NoaGFyLTM5LXMtaG9tZXN0YXktbmV3LWRlbGhpMkgzWANobIgBAZgBCbgBGcgBD9gBA-gBAYgCAagCA7gC6buo8wXAAgE;sid=a6b48726aa2728f7c64464173c262591;dist=0&keep_landing=1&sb_price_type=total&type=total&'`; 
const makemytrip=`https://www.makemytrip.com/hotels/nina_kochhar_s_homestay-details-delhi.html`;
const airbnb=`https://www.airbnb.co.in/rooms/435930?source_impression_id=p3_1584013484_iganaHP5rCmSUJ6U`;
const goibibo=`https://www.goibibo.com/hotels/nina-kochhars-homestay-hotel-in-delhi-8963740855002402435/`;
const trivago=`https://www.trivago.in/delhi-84766/hotel/nina-kochhars-homestay-4988588`;
const yatra=`https://www.yatra.com/hotels/hotels-in-new-delhi/nina-kochhars-homestay/review`;
const agoda=` <RatingsCard  site="Trivago" asOf="March 2020" rating='0' ratingOutOf='0' noOfReviews="0" link={trivago}/>`;
const travelguru=`https://www.travelguru.com/hotels/hotels-in-new-delhi/nina-kochhars-homestay`;
const mytravelpie=`https://www.mytravelpie.com/rooms/10143`;

class Ratings extends Component {  


  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/ratings' className="insidePageContainer__link">Ratings</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                  <div className="ratingsContainer">
                    <img className="logo logo1" src={Logo1} />
                    <div className="logo1Card">
                      <RatingsCard  site="Trip Advisor" asOf="March 2020" rating='5.0' ratingOutOf='5' noOfReviews="66" link={tripAdvisor}/>
                    </div>
                    <img className="logo logo2" src={Logo2} />
                    <div className="logo2Card">
                      <RatingsCard  site="Booking.com" asOf="March 2020" rating='9.0' ratingOutOf='10' noOfReviews="40" link={booking}/>
                    </div>
                    <img className="logo logo3" src={Logo3} />
                    <div className="logo3Card">
                      <RatingsCard  site="Make My Trip" asOf="March 2020" rating='4.3' ratingOutOf='5' noOfReviews="9" link={makemytrip}/>
                    </div>
                    <img className="logo logo4" src={Logo4} />
                    <div className="logo4Card">
                      <RatingsCard  site="Airbnb" asOf="March 2020" rating='4.87' ratingOutOf='5' noOfReviews="30" link={airbnb}/>
                    </div>
                    <img className="logo logo5" src={Logo5} />
                    <div className="logo5Card">
                      <RatingsCard  site="Goibibo" asOf="March 2020" rating='5.0' ratingOutOf='5' noOfReviews="1" link={goibibo}/>
                    </div>
                    <img className="logo logo6" src={Logo6} />
                    <div className="logo6Card">
                      <RatingsCard  site="Trivago" asOf="March 2020" rating='0' ratingOutOf='0' noOfReviews="0" link={trivago}/>
                    </div>
                    <img className="logo logo7" src={Logo7} />
                    <div className="logo7Card">
                      <RatingsCard  site="Yatra" asOf="March 2020" rating='5.0' ratingOutOf='5' noOfReviews="66" link={yatra}/>
                    </div>
                    <img className="logo logo8" src={Logo8} />
                    <div className="logo8Card">
                      <RatingsCard  site="Agoda" asOf="March 2020" rating='9.1' ratingOutOf='10' noOfReviews="46" link={agoda}/>
                    </div>
                    <img className="logo logo9" src={Logo9} />
                    <div className="logo9Card">
                      <RatingsCard  site="Travel Guru" asOf="March 2020" rating='5.0' ratingOutOf='5' noOfReviews="66" link={travelguru}/>
                    </div>
                    <img className="logo logo10" src={Logo10} />
                    <div className="logo10Card">
                      <RatingsCard  site="My Travel Pie" asOf="March 2020" rating='0' ratingOutOf='0' noOfReviews="0" link={mytravelpie}/>                    
                    </div>
                  </div>      
                                       
                </div>
                <Footer />
        </div>
      );
    }
}

export default Ratings;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileMenu extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        showMobileMenu: true
      }
    }

    toggleShowMobileMenu = () => {
      this.setState({showMobileMenu: !this.state.showMobileMenu});
    } 

    render() {
        return (
            this.state.showMobileMenu && <div className="mobileMenu">
                <ul className="mobileMenu__menuItems"> 
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/about' className="link">About</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/reviews' className="link">Reviews</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/ratings' className="link">Ratings</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/room' className="link">Room</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/tariff' className="link">Tariff</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/location' className="link">Location</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/cooking' className="link">Cooking</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/yoga' className="link">Yoga</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/art' className="link">Art</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu} href="http://ninakochhar.blogspot.com/" className="link" target="_blank">Blog</a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/contact' className="link">Contact</Link></a></li>
                </ul>
            </div>
        );
    }
}

export default MobileMenu;
import logo from "./images/NinasHomeStayLogo.png";


import Imghamburger from "./images/hamburger2.png";
import Imgcross from "./images/cross.png";
import MobileMenu from "./mobileMenu";

import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Header extends Component {
  constructor(props)
    {
      super(props);
      this.state={
        showMobileMenu: false
      }
    }

    toggleShowMobileMenu = () => {
      this.setState({showMobileMenu: !this.state.showMobileMenu});
    } 

  render() {
      return (

        <div className="header-container">
            <div className="header-container__hamburgerIcon">
                <img src={Imghamburger} onClick={this.toggleShowMobileMenu}/>
            </div>

            <ul className="header-container__menuItems"> 
              <li><Link to ='/about' className="link">About</Link></li>
              <li><Link to ='/reviews' className="link">Reviews</Link></li>
              <li><Link to ='/ratings' className="link">Ratings</Link></li>
              <li><Link to ='/room' className="link">Room</Link></li>
              <li><Link to ='/tariff' className="link">Tariff</Link></li>
              <li><Link to ='/location' className="link">Location</Link></li>
              <li><Link to ='/cooking' className="link">Cooking</Link></li>
              <li><Link to ='/yoga' className="link">Yoga</Link></li>
              <li><Link to ='/art' className="link">Art</Link></li>
              <li>
                <a href="http://ninakochhar.blogspot.com/" className="link" target="_blank">Blog</a>
              </li>
              <li><Link to ='/contact' className="link">Contact</Link></li>
            </ul>
            <div className="header-container__logo">
                <Link to ='/' className="link"><img  className="header-container__logo--image" src={logo} /></Link>
            </div>

            { this.state.showMobileMenu &&  <div className="header-container__mobileMenu"><MobileMenu /></div> }

      </div>

      );

    }
  };

export default Header;

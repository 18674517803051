import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/cooking.jpg"

class Cooking extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/cooking' className="insidePageContainer__link">Cooking</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                    <p>COOKING CLASSES</p>
                    <p>While in India, why not learn to cook Indian Cuisine!</p>
                    <p>Make a menu:</p>
                    <ul>
                    <li>
                    <p>Dal of your choice</p>
                    </li>
                    <li>
                    <p>Chicken Curry or Butter Paneer</p>
                    </li>
                    <li>
                    <p>Baigan ka Barta (Smokey Eggplant Salsa)</p>
                    </li>
                    <li>
                    <p>Kheera Raita (Cucumber/Yoghurt sauce)</p>
                    </li>
                    <li>
                    <p>Indian Bread (naan, paratha, chapatti)</p>
                    </li>
                    <li>
                    <p>Salad /Pickle</p>
                    </li>
                    <li>
                    <p>Dessert - phirni (rice flour ) or Shahi Tukra or Red carrot kheer (in season). Grated carrot reduced in milk and cardamon.</p>
                    </li>
                    </ul>
                    <p>A one-and-a-half hour demo cum explanation of What’s Cooking and How!</p>
                    <p>Rs.5000/- for 2 people.</p>
                    <p>Please give sufficient advance notice, preferably the day before.</p>
                    </div>
                    </div>
                <Footer />
        </div>
      );
    }
}

export default Cooking;

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/yoga.jpg"

class About extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/yoga' className="insidePageContainer__link">Yoga</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                   <p>Yoga classes are availble on premises for more information please see <a target='_blank' href="https://zenmoksha.in">zenmoksha.in</a></p>
                </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default About;

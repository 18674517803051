import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/8.jpg"

class About extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/location' className="insidePageContainer__link">Location</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                    <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14002.591674205858!2d77.2243489!3d28.670259!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd63858f9aa8f2bd4!2sNina%20Kochhar&#39;s%20Homestays!5e0!3m2!1sen!2sin!4v1584184869830!5m2!1sen!2sin" style={{width: "80%" ,height: "auto", frameborder:"0", style: "border:0"}} ></iframe>
                </div>
                    
                    <p>What’s nearby</p>
                    <p>Maharana Pratap Inter State Bus Terminus</p>
                    <p>0.8 km</p>
                    <p>Kashmere Gate Metro Station</p>
                    <p>0.8 km</p>
                    <p>St. James Church</p>
                    <p>1.1 km</p>
                    <p>Old Delhi railway station</p>
                    <p>1.5 km</p>
                    <p>Gandhi Park</p>
                    <p>1.8 km</p>
                    <p>St. Stephen’s College</p>
                    <p>1.9 km</p>
                    <p>Chandni Chowk Metro station</p>
                    <p>1.9 km</p>
                    <p>Delhi University</p>
                    <p>2.1 km</p>
                    <p>Gurudwara Sis Ganj Sahib</p>
                    <p>2.1 km</p>
                    <p>Salimgarh Fort</p>
                    <p>2.2 km</p>
                    <p>Restaurants and markets *</p>
                    <p>In &amp; Out Supermarket</p>
                    <p>0.2 km</p>
                    <p>Embassy Restaurant</p>
                    <p>1 km</p>
                    <p>Closest airports *</p>
                    <p>Delhi International Airport</p>
                    <p>17.9 km</p>
                    <p>Most popular landmarks *</p>
                    <p>Red Fort</p>
                    <p>2.5 km</p>
                    <p>Jama Masjid</p>
                    <p>2.7 km</p>
                    <p>Raj Ghat</p>
                    <p>3.6 km</p>
                    <p>National Gandhi Museum</p>
                    <p>4.4 km</p>
                    <p>Feroz Shah Kotla Cricket Stadium</p>
                    <p>4.4 km</p>
                    <p>Jantar Mantar</p>
                    <p>5.3 km</p>
                    <p>Gurudwara Bangla Sahib</p>
                    <p>5.6 km</p>
                    <p>India Gate</p>
                    <p>6.9 km</p>
                    <p>Rashtrapati Bhavan</p>
                    <p>7.2 km</p>
                    <p>Gandhi Smriti</p>
                    <p>8.5 km</p>
                    </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default About;




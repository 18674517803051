import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

function displayStars(noOfStars) {
var i;
let stars=[];
for (i = 0; i < noOfStars; i++) {
  stars.push(<FontAwesomeIcon icon={faStar} />);
} 
return stars;
}


const RatingsCard = (props) => {
    const noOfStars = Math.round(props.rating);
        return (
            <div className="ratingsCard">
               <p>Nina's Home Stay Rating: {props.rating} / {props.ratingOutOf}</p>
               <span>{displayStars(noOfStars)}</span>
               <p>Out of {props.noOfReviews} Reviews</p>
               <p>As of {props.asOf}</p>
               <a target='_blank' href={props.link}>View on {props.site} </a>
            </div>
        )
    }

export default RatingsCard;
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import page1 from "./images/guestbook/1.jpg";
import page2 from "./images/guestbook/2.jpg";
import page3 from "./images/guestbook/3.jpg";
import page4 from "./images/guestbook/4.jpg";
import page5 from "./images/guestbook/5.jpg";
import page6 from "./images/guestbook/6.jpg";
import page7 from "./images/guestbook/7.jpg";
import page8 from "./images/guestbook/8.jpg";
import page9 from "./images/guestbook/9.jpg";
import page10 from "./images/guestbook/10.jpg";
import page11 from "./images/guestbook/11.jpg";
import page12 from "./images/guestbook/12.jpg";
import page13 from "./images/guestbook/13.jpg";
import page14 from "./images/guestbook/14.jpg";
import page15 from "./images/guestbook/15.jpg";
import page16 from "./images/guestbook/16.jpg";
import page17 from "./images/guestbook/17.jpg";
import page18 from "./images/guestbook/18.jpg";
import page19 from "./images/guestbook/19.jpg";
import page20 from "./images/guestbook/20.jpg";
import page21 from "./images/guestbook/21.jpg";
import page22 from "./images/guestbook/22.jpg";
import page23 from "./images/guestbook/23.jpg";
import page24 from "./images/guestbook/24.jpg";
import page25 from "./images/guestbook/25.jpg";
import page26 from "./images/guestbook/26.jpg";
import page27 from "./images/guestbook/27.jpg";
import page28 from "./images/guestbook/28.jpg";
import page29 from "./images/guestbook/29.jpg";
import page30 from "./images/guestbook/30.jpg";
import page31 from "./images/guestbook/31.jpg";
import page32 from "./images/guestbook/32.jpg";
import page33 from "./images/guestbook/33.jpg";
import page34 from "./images/guestbook/34.jpg";
import page35 from "./images/guestbook/35.jpg";
import page36 from "./images/guestbook/36.jpg";
import page37 from "./images/guestbook/37.jpg";
import page38 from "./images/guestbook/38.jpg";
import page39 from "./images/guestbook/39.jpg";
import page40 from "./images/guestbook/40.jpg";
import page41 from "./images/guestbook/41.jpg";
import page42 from "./images/guestbook/42.jpg";
import page43 from "./images/guestbook/43.jpg";
import page44 from "./images/guestbook/44.jpg";
import page45 from "./images/guestbook/45.jpg";
import page46 from "./images/guestbook/46.jpg";
import page47 from "./images/guestbook/47.jpg";
import page48 from "./images/guestbook/48.jpg";
import page49 from "./images/guestbook/49.jpg";
import page50 from "./images/guestbook/50.jpg";
import page51 from "./images/guestbook/51.jpg";
import page52 from "./images/guestbook/52.jpg";
import page53 from "./images/guestbook/53.jpg";
import page54 from "./images/guestbook/54.jpg";
import page55 from "./images/guestbook/55.jpg";
import page56 from "./images/guestbook/56.jpg";
import page57 from "./images/guestbook/57.jpg";
import page58 from "./images/guestbook/58.jpg";
import page59 from "./images/guestbook/59.jpg";
import page60 from "./images/guestbook/60.jpg";
import page61 from "./images/guestbook/61.jpg";
import page62 from "./images/guestbook/62.jpg";
import page63 from "./images/guestbook/63.jpg";
import page64 from "./images/guestbook/64.jpg";
import page65 from "./images/guestbook/65.jpg";
import page66 from "./images/guestbook/66.jpg";
import page67 from "./images/guestbook/67.jpg";
import page68 from "./images/guestbook/68.jpg";
import page69 from "./images/guestbook/69.jpg";
import page70 from "./images/guestbook/70.jpg";
import page71 from "./images/guestbook/71.jpg";
import page72 from "./images/guestbook/72.jpg";

import Review from "./review";

function Reviews() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/ratings' className="insidePageContainer__link">Ratings</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                    <div className="cardBillionaireBuddha">
                        {<Review page={page1}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page2}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page3}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page4}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page5}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page6}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page7}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page8}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page9}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page10}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page11}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page12}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page13}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page14}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page15}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page16}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page17}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page18}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page19}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page20}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page21}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page22}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page23}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page24}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page25}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page26}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page27}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page28}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page29}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page30}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page31}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page32}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page33}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page34}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page35}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page36}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page37}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page38}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page39}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page40}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page41}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page42}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page43}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page44}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page45}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page46}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page47}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page48}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page49}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page50}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page51}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page52}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page53}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page54}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page55}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page56}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page57}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page58}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page59}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page60}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page61}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page62}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page63}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page64}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page65}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page66}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page67}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page68}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page69}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page70}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page71}/>}                        
                    </div>
                    <div className="cardBillionaireBuddha">
                        {<Review page={page72}/>}                        
                    </div>
                </div>                                       
                <Footer />
        </div>
      );
    }


export default Reviews;

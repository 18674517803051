import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";


import mainImage from "./images/slideshow/4.jpg"

class Tariff extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/tariff' className="insidePageContainer__link">Tariff</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <p>TARIFF:</p>
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                        <div className="copy__items">
                          <p><strong>ROOM</strong></p>
                          <p>Single occupancy Rs.3000/- per nite inclusive of breakfast</p>
                          <p>Double occupancy Rs.3500/- per nite inclusive of breakfast</p>
                          <p>GST is extra where applicable.</p>
                       </div>
                        <div className="copy__items">
                          <p><strong>YOGA</strong></p>
                          <p>Yoga class…per hour…Rs.1000/-</p>
                        </div>
                      <div className="copy__items">
                      <p><strong>COOKING</strong></p>
                        <p>Cooking class</p>
                        <p>Per 1 1/2 hours for 2 persons</p>
                        <p>Inclusive of eating what has been prepared.</p>
                        <p>Rs. 5000/-</p>
                      </div>
                  </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default Tariff;

import React, { Component } from 'react';

import Slideshow1 from "./images/slideshow/1.jpg";
import Slideshow2 from "./images/slideshow/2.jpg";
import Slideshow3 from "./images/slideshow/3.jpg";
import Slideshow4 from "./images/slideshow/4.jpg";
import Slideshow5 from "./images/slideshow/5.jpg";
import Slideshow6 from "./images/slideshow/6.jpg";
import Slideshow7 from "./images/slideshow/7.jpg";
import Slideshow8 from "./images/slideshow/8.jpg";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

class Slideshow extends Component {
    render() {
      return (

          <div className="slideshow slideshow uk-home-slideshow uk-slideshow-fullscreen uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow="autoplay: true; animation: fade; autoplay-interval: 5000; pause-on-hover: false " >
              <ul className="uk-slideshow-items" data-uk-height-viewport="offset-top: true;">
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow1} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div>
                      
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow2} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div>
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow3} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div>
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow4} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                    </div> 
                </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow5} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div> 
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow6} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div> 
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow7} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div> 
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img src={Slideshow8} alt="" data-uk-cover/>
                      <div className="overlayText uk-position-center-left uk-position-large uk-dark">
                      </div> 
                  </div>
                  </li>
            
              </ul>

            <a href="#" data-uk-slidenav-previous="" className="uk-slidenav-previous uk-icon uk-slidenav uk-slidenav-large uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slideshow-item="previous"></a>
            <a href="#" data-uk-slidenav-next="" className="uk-slidenav-next uk-icon uk-slidenav uk-slidenav-large uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slideshow-item="next"></a>

          </div>

      );
}
};

export default Slideshow;

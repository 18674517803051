import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

import mainImage from "./images/slideshow/5.jpg"

class About extends Component {  

  render() {
      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/about' className="insidePageContainer__link">About</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                <div className="mainImage">
                      <img src={mainImage} />
                    </div>

                    <div className="copy">
                    <p>Its QUIET!</p>
                    <p>Imagine, if you can, in the noisiest part of Old Delhi, a serene haven, somebody’s quiet residence with a lovely terrace garden…in the setting of the green, green grass of a home. And a pine tree peeping in at the window!</p>
                    <p>Six acres of lush gardens, tall and scented hedges, a jettison of a fountain, all immaculately maintained. Even to the point of being sprayed against mosquitoes in summer.</p>
                    <p>This is a property built by the Oberoi Group, reputed for their fine hotels…</p>
                    <p>Each guest is a VIP with the host at NINA KOCHHAR’S HOMESTAY (because she has just one room to let). She ensures that her breakfasts are bountiful and varied and that each guest gets her complete attention, personalizing his, her or their stay in every way.</p>
                    <p>So here goes… …</p>
                    </div>
                </div>
                <Footer />
        </div>
      );
    }
}

export default About;
